import {
  ActionableValueDescriptor,
  AnimationValueDescriptor,
  ArrayValueDescriptor,
  AssetMaterialValueDescriptor,
  AssetModelValueDescriptor,
  AssetValueDescriptor,
  AudioValueDescriptor,
  BooleanValueDescriptor,
  ComponentValueDescriptor,
  ConstraintValueDescriptor,
  EntityValueDescriptor,
  FollowTargetValueDescriptor,
  LightValueDescriptor,
  ModelValueDescriptor,
  NumberValueDescriptor,
  SceneValueDescriptor,
  StringValueDescriptor,
  TextValueDescriptor,
  TrajectoryValueDescriptor,
  UIBlockValueDescriptor,
  UIEntityValueDescriptor,
  UIImageValueDescriptor,
  UIOverlayValueDescriptor,
  UITextValueDescriptor,
  UIValueDescriptor,
  ValueDescriptor,
  Vector3ValueDescriptor,
  VideoValueDescriptor,
} from "~/types/Variable";

type ValueConstructor<TValueDescriptor extends ValueDescriptor = ValueDescriptor> = (
  value: TValueDescriptor["value"]
) => TValueDescriptor;

const defaultValueConstructor = (value: any) => value;

const valueConstructorFactory = <TValueDescriptor extends ValueDescriptor>(
  type: TValueDescriptor["type"],
  valueConstructor: ValueConstructor<TValueDescriptor> = defaultValueConstructor
) => {
  return (value: TValueDescriptor["value"]): TValueDescriptor =>
    ({
      type,
      value: valueConstructor(value),
    } as any as TValueDescriptor);
};

export const valueDescriptorConstructors = {
  Number: valueConstructorFactory<NumberValueDescriptor>("number"),
  String: valueConstructorFactory<StringValueDescriptor>("string"),
  Boolean: valueConstructorFactory<BooleanValueDescriptor>("boolean"),
  Vector3: valueConstructorFactory<Vector3ValueDescriptor>("vector3"),
  Array: valueConstructorFactory<ArrayValueDescriptor>("array"),
  Entity: valueConstructorFactory<EntityValueDescriptor>("entity"),
  Audio: valueConstructorFactory<AudioValueDescriptor>("audio"),
  Model: valueConstructorFactory<ModelValueDescriptor>("model"),
  Light: valueConstructorFactory<LightValueDescriptor>("light"),
  Video: valueConstructorFactory<VideoValueDescriptor>("video"),
  Text: valueConstructorFactory<TextValueDescriptor>("text"),
  UI: valueConstructorFactory<UIValueDescriptor>("ui"),
  Component: valueConstructorFactory<ComponentValueDescriptor>("component"),
  Actionable: valueConstructorFactory<ActionableValueDescriptor>("actionable"),
  Animation: valueConstructorFactory<AnimationValueDescriptor>("animation"),
  Trajectory: valueConstructorFactory<TrajectoryValueDescriptor>("trajectory"),
  FollowTarget: valueConstructorFactory<FollowTargetValueDescriptor>("followTarget"),
  Constraint: valueConstructorFactory<ConstraintValueDescriptor>("constraint"),
  Asset: valueConstructorFactory<AssetValueDescriptor>("asset"),
  AssetMaterial: valueConstructorFactory<AssetMaterialValueDescriptor>("assetMaterial"),
  AssetModel: valueConstructorFactory<AssetModelValueDescriptor>("assetModel"),
  UIEntity: valueConstructorFactory<UIEntityValueDescriptor>("uiEntity"),
  UIBlock: valueConstructorFactory<UIBlockValueDescriptor>("uiBlock"),
  UIImage: valueConstructorFactory<UIImageValueDescriptor>("uiImage"),
  UIText: valueConstructorFactory<UITextValueDescriptor>("uiText"),
  Scene: valueConstructorFactory<SceneValueDescriptor>("scene"),
  UIOverlay: valueConstructorFactory<UIOverlayValueDescriptor>("uiOverlay"),
};
